<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 20px 60px 40px 60px;margin-top: 16px;margin-bottom: 25px;">
      <h3 style="text-align: center;margin: 4px;">北京2024年1-10月规模以上第三产业法人单位主要经济指标</h3>
      <h6 style="text-align: right;margin: 4px;">数据来源：北京市统计局</h6>
      <el-table
          :data="data"
          style="width: 100%;margin-bottom: 20px;"
          row-key="name"
          border
          :expand-row-keys="['按隶属关系分组','按注册登记类型分组','按行业分组']"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
            prop="name"
            label="项目"
            width="270">
        </el-table-column>
        <el-table-column
            prop="dws"
            label="单位数(个)"
            width="90" align="right">
        </el-table-column>
        <el-table-column label="收入合计(亿元)" align="center">
          <el-table-column
              prop="srhj"
              label="1-10月" align="right">
          </el-table-column>
          <el-table-column
              prop="srhjtb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="企业营业收入(亿元)" align="center">
          <el-table-column
              prop="yysr"
              label="1-10月" align="right">
          </el-table-column>
          <el-table-column
              prop="yysrtb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="利润总额(亿元)" align="center">
          <el-table-column
              prop="lrze"
              label="1-10月" align="right">
          </el-table-column>
          <el-table-column
              prop="lrzetb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
        <el-table-column label="从业人员平均人数(万人)" align="center">
          <el-table-column
              prop="emp"
              label="1-10月" align="right">
          </el-table-column>
          <el-table-column
              prop="emptb"
              label="同步增长(%)" align="right">
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "MacroEconomy",
  data(){
    return {
      data:[
        {
          "name": "合         计",
          "level": 0,
          "dws": "44622",
          "srhj": "155739.3",
          "srhjtb": "1.8",
          "yysr": "149623.0",
          "yysrtb": "1.7",
          "lrze": "33175.7",
          "lrzetb": "17.4",
          "emp": "654.2",
          "emptb": "-2.6"
        },
        {
          "name": "按隶属关系分组",
          "level": 0,
          "dws": "44622",
          "srhj": "155739.3",
          "srhjtb": "1.8",
          "yysr": "149623.0",
          "yysrtb": "1.7",
          "lrze": "33175.7",
          "lrzetb": "17.4",
          "emp": "654.2",
          "emptb": "-2.6",
          "children": [
            {
              "name": "中央",
              "level": 1,
              "dws": "3767",
              "srhj": "54863.3",
              "srhjtb": "2",
              "yysr": "51898.3",
              "yysrtb": "1.7",
              "lrze": "24068.1",
              "lrzetb": "29",
              "emp": "131.0",
              "emptb": "0.1"
            },
            {
              "name": "地方",
              "level": 1,
              "dws": "40855",
              "srhj": "100876.0",
              "srhjtb": "1.8",
              "yysr": "97724.7",
              "yysrtb": "1.7",
              "lrze": "9107.6",
              "lrzetb": "2.8",
              "emp": "523.2",
              "emptb": "-3.2"
            }
          ]
        },
        {
          "name": "按登记注册统计类别分组",
          "level": 0,
          "dws": "44622",
          "srhj": "155739.3",
          "srhjtb": "1.8",
          "yysr": "149623.0",
          "yysrtb": "1.7",
          "lrze": "33175.7",
          "lrzetb": "17.4",
          "emp": "654.2",
          "emptb": "-2.6",
          "children": [
            {
              "name": "内资",
              "level": 1,
              "dws": "41363",
              "srhj": "120960.2",
              "srhjtb": "1.2",
              "yysr": "114845.0",
              "yysrtb": "1",
              "lrze": "27875.4",
              "lrzetb": "20.4",
              "emp": "550.3",
              "emptb": "-2.4"
            },
            {
              "name": "港澳台商投资",
              "level": 1,
              "dws": "1459",
              "srhj": "21575.3",
              "srhjtb": "10.1",
              "yysr": "21575.3",
              "yysrtb": "10.1",
              "lrze": "2794.0",
              "lrzetb": "19.1",
              "emp": "59.0",
              "emptb": "-3.8"
            },
            {
              "name": "外商投资",
              "level": 1,
              "dws": "1785",
              "srhj": "13163.8",
              "srhjtb": "-4.8",
              "yysr": "13162.8",
              "yysrtb": "-4.8",
              "lrze": "2507.0",
              "lrzetb": "-1.8",
              "emp": "44.9",
              "emptb": "-2.9"
            }
          ]
        },
        {
          "name": "按行业分组",
          "level": 0,
          "dws": "44622",
          "srhj": "155739.3",
          "srhjtb": "1.8",
          "yysr": "149623.0",
          "yysrtb": "1.7",
          "lrze": "33175.7",
          "lrzetb": "17.4",
          "emp": "654.2",
          "emptb": "-2.6",
          "children": [
            {
              "name": "批发和零售业",
              "level": 1,
              "dws": "12487",
              "srhj": "63529.8",
              "srhjtb": "-4.7",
              "yysr": "63529.8",
              "yysrtb": "-4.7",
              "lrze": "2712.9",
              "lrzetb": "-4.4",
              "emp": "62.0",
              "emptb": "-4.3",
              "children": [
                {
                  "name": "批发业",
                  "level": 2,
                  "dws": "9551",
                  "srhj": "55213.2",
                  "srhjtb": "-5.9",
                  "yysr": "55213.2",
                  "yysrtb": "-5.9",
                  "lrze": "2664.1",
                  "lrzetb": "-4.7",
                  "emp": "41.9",
                  "emptb": "-3.8"
                },
                {
                  "name": "零售业",
                  "level": 2,
                  "dws": "2936",
                  "srhj": "8316.7",
                  "srhjtb": "4.2",
                  "yysr": "8316.7",
                  "yysrtb": "4.2",
                  "lrze": "48.7",
                  "lrzetb": "14.2",
                  "emp": "20.1",
                  "emptb": "-5.2"
                }
              ]
            },
            {
              "name": "交通运输、仓储和邮政业",
              "level": 1,
              "dws": "951",
              "srhj": "8424.6",
              "srhjtb": "8.8",
              "yysr": "8424.6",
              "yysrtb": "8.8",
              "lrze": "792.1",
              "lrzetb": "13.3",
              "emp": "47.3",
              "emptb": "-1.7"
            },
            {
              "name": "住宿和餐饮业",
              "level": 1,
              "dws": "3986",
              "srhj": "1191.2",
              "srhjtb": "-3.5",
              "yysr": "1191.2",
              "yysrtb": "-3.5",
              "lrze": "29.8",
              "lrzetb": "-50.9",
              "emp": "36.8",
              "emptb": "-1.4",
              "children": [
                {
                  "name": "住宿业",
                  "level": 2,
                  "dws": "1319",
                  "srhj": "356.6",
                  "srhjtb": "-3.3",
                  "yysr": "356.6",
                  "yysrtb": "-3.3",
                  "lrze": "23.8",
                  "lrzetb": "-28.5",
                  "emp": "8.7",
                  "emptb": "-1.4"
                },
                {
                  "name": "餐饮业",
                  "level": 2,
                  "dws": "2667",
                  "srhj": "834.6",
                  "srhjtb": "-3.6",
                  "yysr": "834.6",
                  "yysrtb": "-3.6",
                  "lrze": "6.0",
                  "lrzetb": "-78.1",
                  "emp": "28.1",
                  "emptb": "-1.3"
                }
              ]
            },
            {
              "name": "信息传输、软件和信息技术服务业",
              "level": 1,
              "dws": "4643",
              "srhj": "25753.0",
              "srhjtb": "11.4",
              "yysr": "25696.7",
              "yysrtb": "11.4",
              "lrze": "4464.0",
              "lrzetb": "12.2",
              "emp": "111.8",
              "emptb": "-4.1",
              "children": [
                {
                  "name": "电信、广播电视和卫星传输服务",
                  "level": 2,
                  "dws": "289",
                  "srhj": "1377.4",
                  "srhjtb": "4.7",
                  "yysr": "1362.8",
                  "yysrtb": "4.4",
                  "lrze": "1971.1",
                  "lrzetb": "15.9",
                  "emp": "7.2",
                  "emptb": "-4.7"
                },
                {
                  "name": "互联网和相关服务",
                  "level": 2,
                  "dws": "856",
                  "srhj": "7367.1",
                  "srhjtb": "5.4",
                  "yysr": "7347.4",
                  "yysrtb": "5.5",
                  "lrze": "614.3",
                  "lrzetb": "33.7",
                  "emp": "16.6",
                  "emptb": "-8.9"
                },
                {
                  "name": "软件和信息技术服务业",
                  "level": 2,
                  "dws": "3498",
                  "srhj": "17008.4",
                  "srhjtb": "14.7",
                  "yysr": "16986.5",
                  "yysrtb": "14.7",
                  "lrze": "1878.6",
                  "lrzetb": "3.4",
                  "emp": "88.0",
                  "emptb": "-3.1"
                }
              ]
            },
            {
              "name": "金融业",
              "level": 1,
              "dws": "2594",
              "srhj": "30532.5",
              "srhjtb": "13.6",
              "yysr": "30487.1",
              "yysrtb": "13.7",
              "lrze": "18817.3",
              "lrzetb": "62.7",
              "emp": "58.6",
              "emptb": "-0.5"
            },
            {
              "name": "房地产业",
              "level": 1,
              "dws": "3595",
              "srhj": "3311.6",
              "srhjtb": "-15.1",
              "yysr": "3307.2",
              "yysrtb": "-15.1",
              "lrze": "415.6",
              "lrzetb": "-22.2",
              "emp": "44.3",
              "emptb": "-3.7"
            },
            {
              "name": "租赁和商务服务业",
              "level": 1,
              "dws": "5926",
              "srhj": "8519.2",
              "srhjtb": "5.3",
              "yysr": "8361.8",
              "yysrtb": "5.4",
              "lrze": "5131.6",
              "lrzetb": "-5",
              "emp": "108.9",
              "emptb": "-1.7"
            },
            {
              "name": "科学研究和技术服务业",
              "level": 1,
              "dws": "3906",
              "srhj": "6632.9",
              "srhjtb": "0.7",
              "yysr": "5637.8",
              "yysrtb": "0.1",
              "lrze": "624.4",
              "lrzetb": "-1.3",
              "emp": "62.9",
              "emptb": "-2.9"
            },
            {
              "name": "水利、环境和公共设施管理业",
              "level": 1,
              "dws": "508",
              "srhj": "613.0",
              "srhjtb": "-1.7",
              "yysr": "404.2",
              "yysrtb": "-4.8",
              "lrze": "8.5",
              "lrzetb": "-58.3",
              "emp": "10.0",
              "emptb": "-8.5"
            },
            {
              "name": "居民服务、修理和其他服务业",
              "level": 1,
              "dws": "3742",
              "srhj": "2762.6",
              "srhjtb": "-11.3",
              "yysr": "2762.6",
              "yysrtb": "-11.3",
              "lrze": "91.6",
              "lrzetb": "-56.2",
              "emp": "86.9",
              "emptb": "-7.1"
            },
            {
              "name": "教育",
              "level": 1,
              "dws": "5367",
              "srhj": "2092.5",
              "srhjtb": "3.2",
              "yysr": "2084.3",
              "yysrtb": "3.2",
              "lrze": "161.1",
              "lrzetb": "9.3",
              "emp": "229.2",
              "emptb": "2.2"
            },
            {
              "name": "卫生和社会工作",
              "level": 1,
              "dws": "3464",
              "srhj": "2566.9",
              "srhjtb": "9.9",
              "yysr": "2566.9",
              "yysrtb": "9.9",
              "lrze": "317.7",
              "lrzetb": "31.3",
              "emp": "112.2",
              "emptb": "2.1"
            },
            {
              "name": "文化、体育和娱乐业",
              "level": 1,
              "dws": "2236",
              "srhj": "1721.3",
              "srhjtb": "-3.5",
              "yysr": "1466.6",
              "yysrtb": "-4.4",
              "lrze": "161.9",
              "lrzetb": "-16.1",
              "emp": "18.7",
              "emptb": "-3.2",
              "children": [
                {
                  "name": "新闻和出版业",
                  "level": 2,
                  "dws": "523",
                  "srhj": "548.6",
                  "srhjtb": "-1.9",
                  "yysr": "501.2",
                  "yysrtb": "-1.9",
                  "lrze": "81.5",
                  "lrzetb": "-15.8",
                  "emp": "6.3",
                  "emptb": "-1.4"
                },
                {
                  "name": "广播、电视、电影和录音制作业",
                  "level": 2,
                  "dws": "686",
                  "srhj": "577.9",
                  "srhjtb": "-7.7",
                  "yysr": "538.3",
                  "yysrtb": "-7.7",
                  "lrze": "85.3",
                  "lrzetb": "-19.4",
                  "emp": "5.2",
                  "emptb": "-3.8"
                },
                {
                  "name": "文化艺术业",
                  "level": 2,
                  "dws": "331",
                  "srhj": "178.3",
                  "srhjtb": "5.3",
                  "yysr": "65.7",
                  "yysrtb": "8.3",
                  "lrze": "0.3",
                  "lrzetb": "-89.9",
                  "emp": "3.0",
                  "emptb": "-1.7"
                },
                {
                  "name": "体育",
                  "level": 2,
                  "dws": "238",
                  "srhj": "118.0",
                  "srhjtb": "-3.0",
                  "yysr": "87.9",
                  "yysrtb": "-3.2",
                  "lrze": "-11.5",
                  "lrzetb": "-",
                  "emp": "1.6",
                  "emptb": "-6.5"
                },
                {
                  "name": "娱乐业",
                  "level": 2,
                  "dws": "458",
                  "srhj": "298.4",
                  "srhjtb": "-3.1",
                  "yysr": "273.6",
                  "yysrtb": "-5.4",
                  "lrze": "6.2",
                  "lrzetb": "-",
                  "emp": "2.4",
                  "emptb": "-6.4"
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
